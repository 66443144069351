<template>
    <header class="MainHeader">
        <div class="container">
            <div class="MainHeader__left">
                <router-link to="/" class="MainHeader__logo">
                    <img
                        src="@/assets/img/common/logo.svg"
                        alt="logo"
                        class="MainHeader__logo_img"
                    />
                    <span class="MainHeader__logo_text">Обучающий центр | Gerchik</span>
                </router-link>
            </div>
            <div class="MainHeader__right">
                <button
                    v-if="this.$store.getters.getAuth && this.$route.params.slug"
                    type="button"
                    :class="this.notificationClass"
                    @click="() => onOpenModalNotifications()"
                ></button>
                <div
                    v-if="this.$store.getters.getAuth"
                    class="MainHeaderProfile"
                    @click="activeDropDown = !activeDropDown"
                >
                    <router-link to="/profile" class="MainHeaderProfile__avatar">
                        <img
                            v-if="this.$store.getters.getAvatar"
                            v-bind:src="`${this.$store.getters.getAvatar}`"
                            alt="header-avatar"
                            class="MainHeaderProfile__avatar_img"
                        />
                    </router-link>
                    <span class="MainHeaderProfile__name">{{
                        this.$store.getters.getName
                    }}</span>
                    <button
                        type="button"
                        :class="
                            activeDropDown
                                ? 'MainHeaderProfile__btn__active'
                                : 'MainHeaderProfile__btn'
                        "
                    ></button>
                </div>
                <div
                    :class="
                        activeDropDown
                            ? 'MainHeaderDropDownMenu__active'
                            : 'MainHeaderDropDownMenu'
                    "
                >
                    <div class="MainHeaderDropDownMenu__active__block">
                        <router-link to="/profile">
                            <img src="@/assets/img/partials/dropDownMenu/user_icon.svg" />
                            <span>Профиль</span>
                        </router-link>
                    </div>
                    <div class="MainHeaderDropDownMenu__active__block">
                        <div @click="logout">
                            <img src="@/assets/img/partials/dropDownMenu/exit_icon.png" />
                            <span>Выйти</span>
                        </div>
                    </div>
                </div>
                <Button
                    v-if="!this.$store.getters.getAuth"
                    extraClass="button--simple"
                    title="Войти"
                    :onClick="() => onOpenModal()"
                />
                <Login v-if="isVisibleModal" :onClose="() => onCloseModal()" />
                <div class="studyCalendarMain">
                    <NotificationCenterPopup
                        v-if="isVisibleNotifications"
                        :onClose="() => onCloseModalNotifications()"
                    />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Button from "@/views/components/Button/Button";
import NotificationCenterPopup from "@/views/partials/MainHeader/notificationCenter/notificationCenterPopup/notificationCenterPopup";
import Login from "@/views/partials/popups/login/login";

export default {
    name: "MainHeader",

    components: {
        Button,
        NotificationCenterPopup,
        Login,
    },

    created() {
        this.$root.$on("unreadNotificationsUpdated", async () => {
            if (this.$store.getters.getUnreadNotificationsCount > 0) {
                this.notificationClass = "MainHeaderNotifications MainHeaderNotifications--newMessages"
            } else {
                this.notificationClass = "MainHeaderNotifications"
            }
        });
    },

    data() {
        return {
            notificationClass: this.$store.getters.getUnreadNotificationsCount > 0 ? "MainHeaderNotifications MainHeaderNotifications--newMessages" : "MainHeaderNotifications",
            isVisibleModal: false,
            activeDropDown: false,
            isVisibleNotifications: false,
        };
    },

    methods: {
        onOpenModal() {
            this.isVisibleModal = true;
        },
        onCloseModal() {
            this.isVisibleModal = false;
        },
        async onOpenModalNotifications() {
            this.isVisibleNotifications = true;
        },
        onCloseModalNotifications() {
            this.isVisibleNotifications = false;
        },
        async logout() {
            this.activeDropDown = false;
            await this.axios.post("/auth/logout");
            await this.$store.dispatch("logOut");
            await this.$store.dispatch("clearUserPrograms");
            await this.$store.dispatch("clearUserOldPrograms");
            await this.$store.dispatch("clearUserProfile");
            await this.$router.push("/");
        },
        isBrowserYandex(){
                 
            let userAgent = navigator.userAgent;
            return userAgent.search(/YaBrowser/) > 0
        },
        isBrowserOpera() {
            return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        }
    },

    async mounted() {
        if (this.$store.getters.getAuth) {
            if (!this.$store.getters.isValidToken) {
                await this.$store.dispatch("logOut");
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "./MainHeader.scss";
</style>
