<template>
    <div class="notificationMessage">
        <p class="notificationMessage__title" v-if="courseTitle">
            {{ courseTitle }}
            <img
                @click="() => onShowNotification()"
                class="notificationMessage__titleIcon"
                alt="dots"
                src="@/assets/img/common/dots.svg"
            />
        </p>
        <div v-if="isNotification">
            <div
                :class="
                    viewAll
                        ? `notificationMessage__item notificationMessage__item--hidden`
                        : `notificationMessage__item`
                "
                v-for="item in notifications"
                :key="item.notification_id"
            >
                <div @click="() => onShowText(item.id, item.isRead)">
                    <p class="title1 notificationMessage__itemHeader">
                        <span>
                            <span>{{ item.type }}</span>
                            <img
                                v-if="item.type === 'Успех'"
                                class="notificationMessage__typeIcon"
                                src="@/assets/img/components/notification/success.svg"
                            />
                            <img
                                v-if="item.type === 'Информационное'"
                                class="notificationMessage__typeIcon"
                                src="@/assets/img/components/notification/inform.svg"
                            />
                            <img
                                v-if="item.type === 'Напоминание'"
                                class="notificationMessage__typeIcon"
                                src="@/assets/img/components/notification/reminder.svg"
                            />
                            <img
                                v-if="item.type === 'Предупреждение'"
                                class="notificationMessage__typeIcon"
                                src="@/assets/img/components/notification/warning.svg"
                            />
                            <img
                                v-if="item.type === 'Другое'"
                                class="notificationMessage__typeIcon"
                                src="@/assets/img/components/notification/other.svg"
                            />
                        </span>
                        <span>{{ item.date }}</span>
                    </p>
                    <p
                        class="notificationMessage__itemText"
                        v-if="item.notificationMessage"
                    >
                        <span class="notificationMessage__itemTextRow">
                            <img
                                :class="
                                    `${
                                        item.isRead
                                            ? 'hidden'
                                            : 'notificationMessage__itemText_icon'
                                    }`
                                "
                                v-bind:src="UnreadIcon"
                            />
                            {{
                                isSubstrText && item.id === selectedId
                                    ? item.notificationMessage
                                    : item.notificationMessage.length > 50
                                    ? `${item.notificationMessage.substr(0, 50)}...`
                                    : item.notificationMessage
                            }}
                        </span>
                        <a
                            v-if="item.notificationMessageButtonLinkNewWindow"
                            :href="item.notificationMessageButtonLink"
                            target="_blank"
                        >
                            <button
                                :class="
                                    `btn-text ${
                                        isSubstrText && item.id === selectedId
                                            ? 'notificationMessage__itemText_button'
                                            : 'hidden'
                                    }`
                                "
                                v-if="item.notificationMessageButtonLink"
                            >
                                {{ item.notificationMessageButton }}
                            </button>
                        </a>
                        <router-link v-else :to="item.notificationMessageButtonLink">
                            <button
                                :class="
                                    `btn-text ${
                                        isSubstrText && item.id === selectedId
                                            ? 'notificationMessage__itemText_button'
                                            : 'hidden'
                                    }`
                                "
                                v-if="item.notificationMessageButtonLink"
                            >
                                {{ item.notificationMessageButton }}
                            </button>
                        </router-link>
                    </p>
                </div>
            </div>
            <p :class="`notificationMessage__itemButton`">
                <button class="btn-text btn-text--small" @click="toggleOpenButton()">
                    {{ loadButtonText }}
                    {{ `(${this.notifications.length})` }}
                </button>
            </p>
        </div>
    </div>
</template>

<script>
import UnreadIcon from "@/assets/img/common/unreadIcon.svg";
export default {
    name: "NotificationMessage",

    components: {},

    props: {
        courseTitle: String,
        notifications: Array,
        notificationMessage: String,
        notificationMessageButton: String,
        notificationMessageButtonLink: String,
        notificationMessageButtonLinkNewWindow: Boolean,
        isRead: Boolean,
    },

    beforeDestroy() {
        this.$root.$off("itemRead");
    },

    data() {
        return {
            UnreadIcon: UnreadIcon,
            loadButtonText: "Открыть все",
            hideLoadButton: false,
            isNotification: true,
            isSubstrText: false,
            selectedId: null,
            viewAll: true,
        };
    },
    methods: {
        onShowNotification() {
            this.isNotification = !this.isNotification;
        },
        async onShowText(id, isRead) {
            if (this.selectedId === id) {
                this.isSubstrText = !this.isSubstrText;
            } else {
                this.selectedId = id;
                this.isSubstrText = true;
            }

            if (id && !isRead) {
                const setNotificationView = await this.axios.post(
                    "/notifications/v2/view",
                    { id }
                );
                if (
                    setNotificationView &&
                    setNotificationView.data &&
                    setNotificationView.data.result
                ) {
                    this.$root.$emit("itemRead", id);
                } else {
                    console.error("Ошибка при сохранении просмотра уведомления");
                }
            }
        },
        toggleOpenButton() {
            this.viewAll = !this.viewAll;
            this.loadButtonText = this.viewAll ? "Открыть все" : "Скрыть";
        },
    },
};
</script>

<style lang="scss">
@import "./NotificationMessage.scss";
</style>
