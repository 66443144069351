<template>
    <footer class="MainFooter">
        <div class="container">
            <div class="MainFooterTop">
                <router-link to="/" class="MainFooterLogo">
                    <img
                        alt="logo"
                        src="@/assets/img/common/logo.svg"
                        class="MainFooterLogo_img"
                    />
                    <span class="MainFooterLogo_text">Gerchik</span>
                </router-link>
                <Socials />
            </div>
            <div class="MainFooterBottom">
                <p>Gerchik.COM ©2008-2023. Все права защищены</p>
                <p>
                    TRADING EDUCATION LP, 24 Station Square, Suite 16, Inverness, IV1 1LD,
                    Scotland, UK, Co. Id. SL26831
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import Socials from "@/views/components/Socials/Socials";

export default {
    name: "MainFooter",

    components: {
        Socials,
    },
};
</script>

<style lang="scss" scoped>
@import "./MainFooter.scss";
</style>
