<template>
    <div :class="alertClasses">
        <div class="alertHeader">
            <div class="h4 colorWhite alertHeader__title">{{alertTitle}}</div>
            <button class="alertHeader__close" @click="onClose">
                <img src="@/assets/img/common/closeWhite24.svg" alt="close" class="imgBlock imgBlock--fill" />
            </button>
        </div>
        <div v-if="text" class="alertContent">
            <p class="alertContent__text">{{text}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Alert",

    watch: {
        "type": function() {
            this.updateAlert();
        },
    },

    beforeMount() {
        this.updateAlert();
    },

    data() {
        return {
            alertTitle: "Сообщение",
            alertClasses: "alert alert--in"
        }
    },

    props: {
        type: String,
        text: String,
        onClose: Function
    },

    methods: {
        updateAlert() {
            if (this.type === "error") {
                this.alertTitle = "Ошибка";
                this.alertClasses = "alert alert--in alert--error";
            } else if (this.type === "success") {
                this.alertTitle = "Успех";
                this.alertClasses = "alert alert--in alert--success";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "Alert.scss";
</style>