<template>
    <div>
        <div v-if="isLoginModalVisible" class="popup-layout login">
            <div class="popup">
                <button type="button" class="popup__close" @click="onClose"></button>
                <p class="h1 popup__title login__title">Войти</p>
                <div class="form">
                    <div class="login__input formgroup">
                        <label class="formgroup__label">
                            Ваш email
                        </label>
                        <Input
                            type="email"
                            placeholder="Введите email"
                            :inputValue="email"
                            :isError="isEmailError"
                            :errorText="emailErrorMessage"
                            :onChange="(e) => onChangeEmailInput(e)"
                        />
                    </div>
                    <input type="hidden" name="fingerprint" id="fingerprint" v-model="fingerprint" required="true">
                    <div class="login__input formgroup">
                        <label class="formgroup__label">
                            Ваш пароль
                        </label>
                        <div class="login__inputWrapper">
                            <Input
                                :type="passwordInputType ? 'text' : 'password'"
                                placeholder="Введите пароль"
                                :inputValue="password"
                                :isError="isEmptyPassword"
                                :errorText="passErrorMessage"
                                :onChange="(e) => onChangePasswordInput(e)"
                            />
                            <img
                                class="login__inputEye"
                                @click="passwordInputType = !passwordInputType"
                                :src="passwordInputType ? require('@/assets/img/partials/popups/login/closeEye.svg') : require('@/assets/img/partials/popups/login/eye.svg')"
                            />
                        </div>
                    </div>
                    <div class="login__button form__submit">
                        <Button
                            title="Войти"
                            extraClass="button--fullWidth"
                            :onClick="() => onValidateInput()"
                        />
                    </div>
                </div>
                <div class="login__resetPass">
                    Забыли пароль? Нажмите
                    <button class="btn-underline" @click="onOpenForgetModal()">
                        сюда
                    </button>
                </div>
            </div>
        </div>

        <forgotPassword
            v-if="isForgetModalVisible"
            :onClose="() => onCloseForgetModal()"
            :onBackButton="() => onClickBack()"
        />
    </div>
</template>

<script>
    import Input from "@/views/components/Input/Input";
    import Button from "@/views/components/Button/Button";
    import forgotPassword from "@/views/partials/popups/forgotPassword/forgotPassword";
    import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

    export default {
        name: "login",

        components: {
            Input,
            Button,
            forgotPassword,
        },

        props: ["onClose"],

        data() {
            return {
                fingerprint         : "",
                email               : "",
                password            : "",
                isLoginModalVisible : true,
                isEmptyPassword     : false,
                isEmailError        : false,
                emailErrorMessage   : "",
                passErrorMessage    : "",
                isForgetModalVisible: false,
                passwordInputType   : false,
            };
        },

        mounted() {
            const fpPromise = FingerprintJS.load({
                apiKey: 'xBh5fKJPtW11fl889D4W', region: 'eu'
            })

            fpPromise
                .then(fp => fp.get())
                .then(result => this.fingerprint = result.visitorId)

        },

        methods: {
            onChangeEmailInput(value) {
                this.email = value;
            },
            onChangePasswordInput(value) {
                this.password = value;
            },
            async onValidateInput() {
                const emailRegex = /\S+@\S+\.\S+/g;
                if (!this.email) {
                    this.isEmailError = true;
                    this.emailErrorMessage = "Введите email";
                }

                if (this.email && !this.email.match(emailRegex)) {
                    this.isEmailError = true;
                    this.emailErrorMessage = "Введите правильный email";
                } else if (this.email && this.email.match(emailRegex)) {
                    this.isEmailError = false;
                    this.emailErrorMessage = null;
                }

                if (this.password === "") {
                    this.isEmptyPassword = true;
                } else {
                    this.isEmptyPassword = false;
                }

                if (this.password !== "" && this.email && this.email.match(emailRegex)) {
                    const data = {
                        email   : this.email,
                        password: this.password,
                        fingerprint: this.fingerprint,
                        device: window.navigator.userAgent
                    };

                    const result = await this.axios.post('/auth', data);
                    if (result && result.data) {
                        if (result.data.result) {
                            await this.$store.dispatch('auth', {
                                token : result.data.token,
                                name  : result.data.userData.firstName,
                                email : result.data.userData.email,
                                avatar: this.$apiUrl.slice(0, -1) + result.data.userData.photo
                            });

                            this.onClose();

                            this.updateUserProgramsStore(result.data.userData.email);
                        } else {
                            this.isEmailError = true;
                            this.isEmptyPassword = true;
                            this.passErrorMessage = result.data.message ? result.data.message : "Ошибка авторизации"
                        }
                    }
                }
            },
            onOpenForgetModal() {
                this.isLoginModalVisible = false;
                this.isForgetModalVisible = true;
            },
            onCloseForgetModal() {
                this.isLoginModalVisible = true;
                this.isForgetModalVisible = false;
                this.onClose();
            },
            onClickBack() {
                this.isLoginModalVisible = true;
                this.isForgetModalVisible = false;
            },

            async updateUserProgramsStore(email) {
                const getUserProgramsDataResult = await this.axios.get('/programs/products?email=' + email);
                if (getUserProgramsDataResult && getUserProgramsDataResult.data && getUserProgramsDataResult.data.result) {
                    await this.$store.dispatch('setUserPrograms', {
                        programs: getUserProgramsDataResult.data.products
                    });
                    await this.$store.dispatch('setUserOldPrograms', {
                        oldPrograms: getUserProgramsDataResult.data.oldProducts
                    });
                } else {
                    console.error("Error with user courses load");
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import "login.scss";
</style>
