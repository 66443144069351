<template>
    <div class="page">
        <MainHeader />

        <div class="main">
            <slot />
        </div>

        <MainFooter />
        <!-- <ScrollToTop /> -->

        <Alert
            v-if="alert.isActive"
            :type="alert.type"
            :text="alert.text"
            :onClose="() => onCloseAlert()"
        />
    </div>
</template>

<script>
import MainHeader from "@/views/partials/MainHeader/MainHeader";
import MainFooter from "@/views/partials/MainFooter/MainFooter";
import ScrollToTop from "@/views/components/ScrollToTop/ScrollToTop";
import Alert from "@/views/partials/Alert/Alert";

export default {
    components: {
        MainHeader,
        MainFooter,
        ScrollToTop,
        Alert,
    },

    beforeMount() {
        this.onCloseAlert();
    },

    mounted() {
        // usedesk script
        let usedeskScript = document.createElement("script");
        usedeskScript.type = "text/javascript";
        usedeskScript.setAttribute(
            "src",
            //"https://lib.usedesk.ru/secure.usedesk.ru/widget_160355_26549.js"
            "/js/widget_160355_26549.js"
        );
        document.head.appendChild(usedeskScript);






        
    },

    computed: {
        alert: function() {
            return this.$store.getters.getAlert;
        },
    },

    methods: {
        async onCloseAlert() {
            await this.$store.dispatch("clearAlert");
        },
    },
};
</script>

<style scoped></style>
