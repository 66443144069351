<template>
    <div>
        <div class="popup-layout forgotPassword">
            <div class="popup forgotPassword__modal">
                <button type="button" class="popup__close" @click="onClose"></button>
                <p class="h1 forgotPassword__title">Забыли пароль</p>
                <p class="text colorGray forgotPassword__subTitle">
                    {{ subtitle }}
                </p>
                <div class="form" v-if="isVisibleForm">
                    <div class="forgotPassword__input formgroup">
                        <label class="formgroup__label">
                            Ваш email
                        </label>
                        <Input
                            type="text"
                            placeholder="Введите email"
                            :inputValue="email"
                            :isError="isEmptyEmail"
                            :errorText="emailErrorMessage"
                            :onChange="(e) => onChangeEmailInput(e)"
                        />
                    </div>
                    <div class="forgotPassword__button">
                        <Button
                            title="Получить"
                            extraClass="button--fullWidth"
                            :onClick="() => onValidateInput()"
                        />
                    </div>
                </div>

                <div class="forgotPassword__result" v-if="isVisibleResult">
                    <div class="forgotPassword__result_email">{{ email }}</div>
                    <div class="forgotPassword__result_btn">
                        <Button
                            title="На главную"
                            extraClass="button--fullWidth"
                            :onClick="() => onClose()"
                        />
                    </div>
                </div>

                <div class="forgotPassword__button-reset" v-if="isVisibleBackBtn">
                    <button class="text btn-underline" @click="() => onBackButton()">
                        Назад
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from "@/views/components/Input/Input";
import Button from "@/views/components/Button/Button";
export default {
    name: "forgotPassword",

    components: {
        Input,
        Button,
    },

    props: ["onClose", "onBackButton"],

    data() {
        return {
            subtitle: "Мы отправим инструкцию по восстановлению пароля на Ваш email",
            email: "",
            isEmptyEmail: false,
            emailErrorMessage: "",
            isVisibleForm: true,
            isVisibleResult: false,
            isVisibleBackBtn: true,
        };
    },

    methods: {
        onChangeEmailInput(value) {
            this.email = value;
        },
        async onValidateInput() {
            const emailRegex = /\S+@\S+\.\S+/g;
            if (!this.email) {
                this.isEmptyEmail = true;
                this.emailErrorMessage = "Введите email";
            }

            if (this.email && !this.email.match(emailRegex)) {
                this.isEmptyEmail = true;
                this.emailErrorMessage = "Введите правильный email";
            } else if (this.email && this.email.match(emailRegex)) {
                this.isEmailError = false;
                this.emailErrorMessage = null;
            }

            if (this.email && this.email.match(emailRegex)) {
                const data = {
                    email: this.email,
                };

                const result = await this.axios.post("/auth/forget", data);
                if (result && result.data && result.data.result) {
                    this.subtitle = "Мы отправили инструкции на Ваш email:";
                    this.isVisibleForm = false;
                    this.isVisibleResult = true;
                    this.isVisibleBackBtn = false;
                }
            }
        },
        onCloseModal() {
            this.onClose();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "forgotPassword.scss";
</style>
