var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notificationMessage"},[(_vm.courseTitle)?_c('p',{staticClass:"notificationMessage__title"},[_vm._v(" "+_vm._s(_vm.courseTitle)+" "),_c('img',{staticClass:"notificationMessage__titleIcon",attrs:{"alt":"dots","src":require("@/assets/img/common/dots.svg")},on:{"click":function () { return _vm.onShowNotification(); }}})]):_vm._e(),(_vm.isNotification)?_c('div',[_vm._l((_vm.notifications),function(item){return _c('div',{key:item.notification_id,class:_vm.viewAll
                    ? "notificationMessage__item notificationMessage__item--hidden"
                    : "notificationMessage__item"},[_c('div',{on:{"click":function () { return _vm.onShowText(item.id, item.isRead); }}},[_c('p',{staticClass:"title1 notificationMessage__itemHeader"},[_c('span',[_c('span',[_vm._v(_vm._s(item.type))]),(item.type === 'Успех')?_c('img',{staticClass:"notificationMessage__typeIcon",attrs:{"src":require("@/assets/img/components/notification/success.svg")}}):_vm._e(),(item.type === 'Информационное')?_c('img',{staticClass:"notificationMessage__typeIcon",attrs:{"src":require("@/assets/img/components/notification/inform.svg")}}):_vm._e(),(item.type === 'Напоминание')?_c('img',{staticClass:"notificationMessage__typeIcon",attrs:{"src":require("@/assets/img/components/notification/reminder.svg")}}):_vm._e(),(item.type === 'Предупреждение')?_c('img',{staticClass:"notificationMessage__typeIcon",attrs:{"src":require("@/assets/img/components/notification/warning.svg")}}):_vm._e(),(item.type === 'Другое')?_c('img',{staticClass:"notificationMessage__typeIcon",attrs:{"src":require("@/assets/img/components/notification/other.svg")}}):_vm._e()]),_c('span',[_vm._v(_vm._s(item.date))])]),(item.notificationMessage)?_c('p',{staticClass:"notificationMessage__itemText"},[_c('span',{staticClass:"notificationMessage__itemTextRow"},[_c('img',{class:("" + (item.isRead
                                        ? 'hidden'
                                        : 'notificationMessage__itemText_icon')),attrs:{"src":_vm.UnreadIcon}}),_vm._v(" "+_vm._s(_vm.isSubstrText && item.id === _vm.selectedId ? item.notificationMessage : item.notificationMessage.length > 50 ? ((item.notificationMessage.substr(0, 50)) + "...") : item.notificationMessage)+" ")]),(item.notificationMessageButtonLinkNewWindow)?_c('a',{attrs:{"href":item.notificationMessageButtonLink,"target":"_blank"}},[(item.notificationMessageButtonLink)?_c('button',{class:("btn-text " + (_vm.isSubstrText && item.id === _vm.selectedId
                                        ? 'notificationMessage__itemText_button'
                                        : 'hidden'))},[_vm._v(" "+_vm._s(item.notificationMessageButton)+" ")]):_vm._e()]):_c('router-link',{attrs:{"to":item.notificationMessageButtonLink}},[(item.notificationMessageButtonLink)?_c('button',{class:("btn-text " + (_vm.isSubstrText && item.id === _vm.selectedId
                                        ? 'notificationMessage__itemText_button'
                                        : 'hidden'))},[_vm._v(" "+_vm._s(item.notificationMessageButton)+" ")]):_vm._e()])],1):_vm._e()])])}),_c('p',{class:"notificationMessage__itemButton"},[_c('button',{staticClass:"btn-text btn-text--small",on:{"click":function($event){return _vm.toggleOpenButton()}}},[_vm._v(" "+_vm._s(_vm.loadButtonText)+" "+_vm._s(("(" + (this.notifications.length) + ")"))+" ")])])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }