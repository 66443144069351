<template>
    <div>
        <router-link
            v-if="isLink && !isExternalLink"
            :to="link"
            :class="(extraClass || '') + ' button'"
            :disabled="isDisabled"
            @click.native="onClick"
        >
            {{ title }}
        </router-link>
        <a
            v-else-if="isLink && isExternalLink"
            :href="link"
            target="_blank"
            :class="(extraClass || '') + ' button'"
            :disabled="isDisabled"
        >
            {{ title }}
        </a>
        <button
            v-else
            :class="(extraClass || '') + ' button'"
            :disabled="isDisabled"
            :type="btnType ? btnType : 'button'"
            @click="onClick"
        >
            {{ title }}
        </button>
    </div>
</template>

<script>
export default {
    name: "Button",

    props: {
        title: String,
        extraClass: String,
        isDisabled: Boolean,
        onClick: Function,
        isLink: Boolean,
        isExternalLink: Boolean,
        link: String,
        btnType: String,
    }
};
</script>

<style lang="scss" scoped>
@import "./button.scss";
</style>
