<template>
    <div class="popup-layout popup-layout--2">
        <div class="popup popup--13 notificationCenter">
            <div class="notificationCenterHeader">
                <!--<router-link v-if="this.$route.path !== '/notifications'" class="h3 btn-text" to="/notifications">
                    перейти в центр уведомлений
                </router-link>-->
                <p>&nbsp;</p>
                <button type="button" class="popup__close" @click="onClose"></button>
            </div>

            <div class="notificationCenterText">
                <p class="h4">Новые уведомления</p>
                <label class="notificationSelector">
                    <p class="notificationSelector__text">
                        Тип:
                    </p>
                    <SelectCustom
                        :onSelectItem="(event) => onChangeSelectOption(event)"
                        :options="notificationFilterSelect"
                    />
                </label>

                <div v-if="!noNotifications">
                    <img
                        class="notificationCenterDropDown"
                        alt="dots"
                        src="@/assets/img/common/dots.svg"
                        @click="activeDropDown = !activeDropDown"
                    />
                    <div
                        class="notificationCenterDropDown__content colorLynch text2"
                        v-if="activeDropDown"
                        @click="markAllRead()"
                    >
                        Отметить все прочитанными
                    </div>
                </div>
            </div>

            <div class="customScroll notificationCenterContentWrapper">
                <div
                    class="notificationCenterContent"
                    v-for="item in notificationItems"
                    :key="item.id"
                >
                    <NotificationMessage
                        :courseTitle="item.courseTitle"
                        :notifications="
                            item.notifications.filter((value) =>
                                selectedOption ? value.type === selectedOption : value
                            )
                        "
                        :notificationMessage="item.notificationMessage"
                        :notificationMessageButton="item.notificationMessageButton"
                    />
                </div>
                <p v-if="noNotifications" class="colorGray">
                    На данный момент новых уведомлений нет
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import SelectCustom from "@/views/components/SelectCustom/SelectCustom";
import NotificationMessage from "@/views/components/NotificationMessage/NotificationMessage";
import axios from "axios";

export default {
    name: "notificationCenterPopup",

    components: {
        Button,
        SelectCustom,
        NotificationMessage,
    },

    props: ["onClose"],

    async created() {
        const $this = this;
        this.$root.$on("itemRead", async (id) => {
            await $this
                .updateUnreadNotifications()
                .then(async (updateUnreadNotificationsResult) => {
                    if (updateUnreadNotificationsResult) {
                        $this.notificationItems[0].notifications = $this.notificationItems[0].notifications.filter(
                            (item) => {
                                if (item.id === id) {
                                    item.isRead = true;
                                }

                                return item;
                            }
                        );
                    } else {
                        await $this.$store.dispatch("setAlert", {
                            alert: {
                                isActive: true,
                                type: "error",
                                text: "Ошибка при обновлении непрочитанных уведомлений",
                            },
                        });
                    }
                });
        });
    },

    async mounted() {
        const $this = this;
        await $this.loadNotifications(0, 0).then(async (loadNotificationsResult) => {
            if (loadNotificationsResult.result) {
                await $this
                    .updateNotifications(loadNotificationsResult)
                    .then(async (updateNotificationsResult) => {
                        if (updateNotificationsResult) {
                            await $this
                                .updateUnreadNotifications()
                                .then(async (updateUnreadNotificationsResult) => {
                                    if (updateUnreadNotificationsResult) {
                                        $this.renderNotifications();
                                    } else {
                                        await $this.$store.dispatch("setAlert", {
                                            alert: {
                                                isActive: true,
                                                type: "error",
                                                text:
                                                    "Ошибка при обновлении непрочитанных уведомлений",
                                            },
                                        });
                                    }
                                });
                        } else {
                            await $this.$store.dispatch("setAlert", {
                                alert: {
                                    isActive: true,
                                    type: "error",
                                    text: "Ошибка при сохранении уведомлений",
                                },
                            });
                        }
                    });
            } else {
                await $this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при загрузке уведомлений",
                    },
                });
            }
        });
    },

    data() {
        return {
            noNotifications: false,
            activeDropDown: false,
            notificationItems: [],
            selectedOption: "",
            notificationFilterSelect: [
                {
                    text: "Все",
                    value: "",
                },
                {
                    text: "Успех",
                    value: "Успех",
                },
                {
                    text: "Информационное",
                    value: "Информационное",
                },
                {
                    text: "Напоминание",
                    value: "Напоминание",
                },
                {
                    text: "Предупреждение",
                    value: "Предупреждение",
                },
                {
                    text: "Другое",
                    value: "Другое",
                },
            ],
        };
    },

    methods: {
        onCloseModal() {
            this.onClose();
        },

        onChangeSelectOption(value) {
            this.selectedOption = value;
        },

        async loadNotifications(skip, limit) {
            const data = {
                skip,
                limit,
            };

            if (this.$route.params.slug) {
                data.course = this.$route.params.slug;
            }

            const getNotifications = await this.axios.post("/notifications/list", data);
            if (
                getNotifications &&
                getNotifications.data &&
                getNotifications.data.result
            ) {
                return {
                    ...getNotifications.data,
                };
            } else {
                console.error("Ошибка при загрузке уведомлений");
                return {
                    result: 0,
                };
            }
        },
        async updateNotifications(notifications) {
            return await this.$store
                .dispatch("setNotifications", notifications)
                .then(async () => {
                    return true;
                });
        },
        async updateUnreadNotifications() {
            const $this = this;
            const getNotificationsCount = await axios.get("/notifications/unread");
            if (
                getNotificationsCount &&
                getNotificationsCount.data &&
                getNotificationsCount.data.result
            ) {
                return await $this.$store
                    .dispatch(
                        "setUnreadNotifications",
                        getNotificationsCount.data.unreadNotifications
                    )
                    .then(() => {
                        $this.$root.$emit("unreadNotificationsUpdated");
                        return true;
                    });
            } else {
                console.error("Ошибка при получении непрочитанных уведомлений");
                return false;
            }
        },
        async markAllRead() {
            const data = {};

            if (this.$route.params.slug) {
                data.course = this.$route.params.slug;
            }

            const markAllReadResult = await this.axios.post(
                "/notifications/all_read",
                data
            );
            if (
                markAllReadResult &&
                markAllReadResult.data &&
                markAllReadResult.data.result
            ) {
                await this.updateUnreadNotifications();

                this.activeDropDown = false;

                for (const notificationItem of this.notificationItems[0].notifications) {
                    notificationItem.isRead = true;
                }

                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "success",
                        text: "Все уведомления отмечены прочитанными",
                    },
                });
            } else {
                this.activeDropDown = false;
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при сохранении уведомлений",
                    },
                });
            }
        },
        renderNotifications() {
            const notifications = this.$store.getters.getNotifications.notifications;

            if (this.$route.params.slug) {
                if (notifications && notifications.length > 0) {
                    this.notificationItems = [];
                    this.notificationItems.push({
                        notifications: [],
                    });
                    for (const notificationItem of notifications) {
                        this.notificationItems[0].notifications.push({
                            id: notificationItem.notification_id,
                            type: notificationItem.type,
                            date: notificationItem.convertedCreatedAt,
                            notificationMessage: notificationItem.message,
                            notificationMessageButton: notificationItem.link
                                ? "Перейти"
                                : null,
                            notificationMessageButtonLink: notificationItem.link
                                ? notificationItem.link
                                : null,
                            notificationMessageButtonLinkNewWindow: notificationItem.newWindow
                                ? true
                                : false,
                            isRead: notificationItem.viewed,
                        });
                    }
                } else {
                    this.noNotifications = true;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "notificationCenterPopup.scss";
</style>
