<template>
    <a @click="scrollToTop()" class="scrollToTop">
        <img
                alt="arrowUp"
                class="scrollToTop__img"
                src="@/assets/img/common/arrowUp.svg"
        />
    </a>
</template>

<script>
    export default {
        name: "scrollToTop",

        methods: {
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "./ScrollToTop.scss";
</style>