<template>
    <div class="selectCustom">
        <div class="input selectCustom__langSelect" @click="() => onClickListActive()">
            {{ selectedOption }}
            <img
                :class="isListActive ? 'selectCustom__imgRotate' : 'selectCustom__img'"
                src="@/assets/img/common/selectDropdown.svg"
            />
        </div>

        <div v-if="this.isListActive" class="selectCustomOptions customScroll">
            <div
                v-for="(item, index) in options"
                :key="index"
                :class="
                        item.text === selectedOption
                            ? 'text2 colorWhite selectCustomOption selectCustomOption--active'
                            : 'text2 colorBlack selectCustomOption'
                    "
                @click="() => setSelectedOption(item.text, item.value)"
            >
                {{ item.text }}
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "SelectCustom",

    components: {
        Button,
    },

    data() {
        return {
            isListActive: false,
            selectedField: {},
            selectedOption: "",
        };
    },

    beforeMount() {
        this.selectedOption = this.options[0].text;
    },

    props: {
        onSelectItem: Function,
        options: [
            {
                text: String,
                value: String,
            },
        ],
    },

    methods: {
        hideMenu() {
            this.isListActive = false;
        },

        onClickListActive() {
            this.isListActive = !this.isListActive;
        },

        setSelectedOption(value, key) {
            this.hideMenu();
            this.selectedOption = value;
            this.onSelectItem(key);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "SelectCustom.scss";
</style>
