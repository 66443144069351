<template>
    <div>
        <input
            :type="type"
            :placeholder="placeholder"
            :value="inputValue"
            :class="isError ? 'input--error' : ''"
            :disabled="disabled"
            @change="(event) => onChangeInput(event)"
        />
        <p v-if="isError" class="h5 error">{{ isError ? errorText : "" }}</p>
    </div>
</template>

<script>
export default {
    props: {
        type: String,
        placeholder: String,
        isError: Boolean,
        errorText: String,
        onChange: Function,
        inputValue: String,
        disabled: Boolean,
    },

    methods: {
        onChangeInput(event) {
            this.onChange(event.target.value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./input.scss";
</style>
