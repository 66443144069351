<template>
    <div class="Socials">
        <a
            href="https://www.youtube.com/user/gerchikalex"
            class="Social Social--yt"
            target="_blank"
            rel="nofollow"
        ></a>
        <a
            href="https://www.instagram.com/gerchik_ru_official/"
            class="Social Social--in"
            target="_blank"
            rel="nofollow"
        ></a>
        <a
            href="https://www.facebook.com/gerchik.official"
            class="Social Social--fb"
            target="_blank"
            rel="nofollow"
        ></a>
        <!-- <a href="#" class="Social Social--vk" target="_blank" rel="nofollow"></a> -->
        <a
            href="https://t.me/AlexanderGerchik"
            class="Social Social--tg"
            target="_blank"
            rel="nofollow"
        ></a>
        <a
            href="https://www.pinterest.com/gerchikofficial/"
            class="Social Social--pin"
            target="_blank"
            rel="nofollow"
        ></a>
        <a
            href="https://www.linkedin.com/in/gerchikofficial/"
            class="Social Social--lin"
            target="_blank"
            rel="nofollow"
        ></a>
        <a
            href="https://www.tiktok.com/@gerchik.official/"
            class="Social Social--tt"
            target="_blank"
            rel="nofollow"
        ></a>
    </div>
</template>

<script>
export default {
    name: "Socials",
};
</script>

<style lang="scss">
@import "./Socials.scss";
</style>
